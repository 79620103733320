import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { CommunityPage } from '../community'

const CommunityEs = ({ data, ...props }) => {
  return (
    <CommunityPage
      {...props}
      data={{ ...data, allCommunityJson: data.allCommunityEsJson }}
    />
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "banner-community.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
        imageSections: allFile(
          filter: { name: { regex: "/^communityImage*/" } }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        screenshots: allFile(
          filter: { name: { regex: "/^communityScreenshot*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 400) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allCommunityEsJson {
          edges {
            node {
              bannerHeadline
              heroHeading
              introSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              secondarySection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              thirdSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              lastSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              valuesTitle
              valuesIconColor
              values {
                icon
                title
                value
              }
              communityGuidelinesString
              communityGuideLink
              communityGuideLinkLabel
              imageSections {
                containerColor
                urlDescription
                title
                flip
                reverse
                button
                storeButton
                buttonLabel
                buttonColor
                buttonLink
                focus
                src
                box
                srcDescription
                titleColor
                firstBody
                secondBody
                thirdBody
              }
              pageTitle
              pageDescription
              socialTitle
              socialDescription
              socialImage
            }
          }
        }
      }
    `}
    render={data => <CommunityEs data={data} {...props} />}
  />
)
